.nav {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    padding-block: 1.25rem;

    @include onDesktop {
      flex-direction: row;
      justify-content: end;
      height: fit-content;
    }

    & > li {
      align-self: center;
    }
  }

  &__link {
    position: relative;
    color: inherit;
    text-decoration: none;
    font-size: 1.38rem;
    line-height: 1.38rem;

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      display: block;
      height: 1px;
      width: 100%;
      background-color: $main-text-color;

      transition: transform $effect-duration;
      transform: scale(0);
      transform-origin: left;
    }

    &:hover::after {
      transform: scale(1);
    }
  }
  &__book-trip {
    align-self: center;
  }
}
