html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  width: 100%;
  height: auto;
}

.container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (width >= 36rem) {
  .container {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

@media (width >= 64rem) {
  .container {
    max-width: 63.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

@keyframes smoothAppear {
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes runningLine {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.scroll-animated {
  opacity: 0;
  transition: opacity 2s, transform 2s;
}

.scroll-animated--fade-in {
  transform: translateY(200px);
}

.scroll-animated--first-fade-in {
  transform: translateY(100px);
}

.scroll-animated--slide-in-left {
  transform: translateX(-100px);
}

.scroll-animated--slide-in-right {
  transform: translateX(200px);
}

.scroll-animated--zoom-in {
  transform: scale(.5);
}

.visible {
  opacity: 1;
  transform: none;
}

.page {
  color: #333;
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  line-height: 140%;
  overflow-x: hidden;
}

.page:has(.page__menu:target) {
  overflow: hidden;
}

.page__body {
  background-color: #bfb0a1;
  min-width: 20rem;
  margin: 0;
  overflow-x: hidden;
}

.page__menu {
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

@media (width >= 64rem) {
  .page__menu {
    display: none;
  }
}

.page__menu:target {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}

.header {
  background-color: #fff;
}

.header__nav {
  display: none;
}

@media (width >= 64rem) {
  .header__nav {
    justify-content: end;
    align-items: center;
    gap: 2rem;
    display: -ms-flexbox;
    display: flex;
  }
}

.header__content {
  display: grid;
}

.header__title {
  text-transform: uppercase;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.header__links {
  margin: 0;
  padding: 0;
}

.header__cta {
  place-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.nav__list {
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  margin: 0;
  padding: 1.25rem 0;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 64rem) {
  .nav__list {
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: end;
    height: fit-content;
  }
}

.nav__list > li {
  align-self: center;
}

.nav__link {
  color: inherit;
  font-size: 1.38rem;
  line-height: 1.38rem;
  text-decoration: none;
  position: relative;
}

.nav__link:after {
  content: "";
  transform-origin: 0;
  background-color: #333;
  width: 100%;
  height: 1px;
  transition: transform .3s;
  display: block;
  position: absolute;
  bottom: -8px;
  transform: scale(0);
}

.nav__link:hover:after {
  transform: scale(1);
}

.nav__book-trip {
  align-self: center;
}

.button {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #bfb0a1;
  border: 1px solid #0000;
  border-radius: 4px;
  outline: none;
  padding: .5rem 1.5rem;
  text-decoration: none;
  transition: all .3s;
}

.button:hover {
  color: #bfb0a1;
  background-color: #fff;
  border-color: #bfb0a1;
}

.button--secondary {
  color: #978879;
  background-color: #fff;
  border-color: #bfb0a1;
}

.button--secondary:hover {
  color: #fff;
  background-color: #bfb0a1;
  border-color: #fff;
}

.slider {
  place-items: center;
  gap: 10px;
  display: grid;
}

.slider__item {
  object-fit: cover;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
}

.slider__item:first-of-type, .slider__item:last-of-type {
  height: 250px;
  display: none;
}

.slider__item:nth-of-type(2), .slider__item:nth-of-type(4) {
  height: 341px;
  display: none;
}

.slider__item:nth-of-type(3) {
  object-position: top;
  height: 471px;
}

@media (width >= 768px) {
  .slider {
    grid-template-columns: repeat(7, 1fr);
  }

  .slider__item:nth-of-type(2), .slider__item:nth-of-type(4) {
    display: block;
  }

  .slider__item:nth-of-type(3) {
    object-fit: cover;
    grid-column: 3 / 6;
    width: 100%;
    height: 421px;
  }

  .slider__item:nth-of-type(2) {
    grid-column: 1 / 3;
  }

  .slider__item:nth-of-type(4) {
    grid-column: 6 / 8;
  }
}

@media (width >= 1200px) {
  .slider {
    grid-template-columns: repeat(6, 1fr);
  }

  .slider__item:first-of-type, .slider__item:last-of-type {
    display: block;
  }

  .slider__item:first-of-type {
    grid-column: 1 / 2;
  }

  .slider__item:last-of-type {
    grid-column: 6 / 7;
  }

  .slider__item:nth-of-type(2) {
    grid-column: 2 / 3;
  }

  .slider__item:nth-of-type(4) {
    grid-column: 5 / 6;
  }

  .slider__item:nth-of-type(3) {
    grid-column: 3 / 5;
  }
}

.icon {
  background-image: url("icon-burger-menu.cad8f927.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform .3s;
  display: block;
}

.icon:hover {
  transform: scale(1.2);
}

@media (width >= 36rem) {
  .icon {
    width: 2rem;
    height: 2rem;
  }
}

.icon--menu {
  background-image: url("icon-burger-menu.cad8f927.svg");
  place-self: end;
  margin-top: 1.25rem;
}

.icon--menu:hover {
  background-image: url("icon-burger-menu-hover.48e7b7c5.svg");
}

@media (width >= 64rem) {
  .icon--menu {
    display: none;
  }
}

.icon--close {
  background-image: url("icon-close.31bb77d6.svg");
}

@media (width >= 64rem) {
  .icon--close {
    display: none;
  }
}

.menu {
  box-sizing: border-box;
  text-transform: uppercase;
  background-color: #fff;
  height: 100vh;
  font-weight: 700;
}

.menu__nav {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 2rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 36rem) {
  .menu__nav {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

@media (width >= 64rem) {
  .menu__nav {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

.menu__nav > .button {
  align-self: end;
}

.menu__nav > .nav__list > li {
  text-align: right;
  align-self: end;
}

.menu__nav > .language-dropdown {
  align-self: end;
}

.menu__nav > .nav__list {
  padding-bottom: 0;
}

.menu__socials {
  align-self: end;
}

.main {
  z-index: 1;
  background-color: #bfb0a1;
  position: relative;
}

.main__content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
}

@media (width >= 36rem) {
  .main__content {
    row-gap: 7.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

.about {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.about__content {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 64rem) {
  .about__content {
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 60px;
    margin-bottom: 2rem;
  }
}

.about__description {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.about__description p {
  font-size: 1.25rem;
  line-height: 1.6rem;
}

@media (width >= 64rem) {
  .about__description {
    width: 50%;
  }
}

.about__photo {
  object-fit: cover;
  border-radius: 4px;
  width: 100%;
  height: 18.75rem;
  display: block;
}

@media (width >= 36rem) {
  .about__photo {
    height: 25rem;
  }
}

@media (width >= 64rem) {
  .about__photo {
    width: 50%;
    height: 31.25rem;
    margin-bottom: 0;
  }
}

.about__links {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  display: -ms-flexbox;
  display: flex;
}

.section-title {
  letter-spacing: -1.5px;
  text-transform: uppercase;
  color: #4c1d12;
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 100%;
}

@media (width >= 36rem) {
  .section-title {
    font-size: 2rem;
  }
}

.section-title--align-center {
  text-align: center;
}

.section-title--light {
  color: #fff;
}

.line {
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow: hidden;
}

@media (width >= 36rem) {
  .line {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.line__content {
  font-size: 2.125rem;
  line-height: 3rem;
  animation: 15s linear infinite runningLine;
  display: inline-block;
}

@media (width >= 36rem) {
  .line__content {
    font-size: 3.125rem;
  }
}

.tourism {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tourism__image {
  object-fit: cover;
  scroll-snap-align: start;
  border-radius: 4px;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  margin-right: 10px;
  display: block;
}

@media (width >= 36rem) {
  .tourism__image {
    break-inside: avoid;
    -ms-flex: none;
    flex: none;
    width: 100%;
    margin-bottom: 15px;
  }
}

.tourism__images-container {
  position: relative;
  overflow: hidden;
}

.tourism__images {
  scroll-snap-type: x mandatory;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 15px;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}

@media (width >= 36rem) {
  .tourism__images {
    columns: 10rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column: 1 / -1;
    display: block;
  }
}

@media (width >= 64rem) {
  .tourism__images {
    grid-column: 1 / 8;
  }
}

.tourism__images::-webkit-scrollbar {
  display: none;
}

@media (width >= 36rem) {
  .tourism__wrapper {
    --columns: 2;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: 1rem 1.25rem;
    display: grid;
  }
}

@media (width >= 36rem) and (width >= 36rem) {
  .tourism__wrapper {
    --columns: 6;
    column-gap: 1.5rem;
  }
}

@media (width >= 36rem) and (width >= 64rem) {
  .tourism__wrapper {
    --columns: 12;
  }
}

@media (width >= 36rem) {
  .tourism__content {
    grid-column: 1 / -1;
  }
}

@media (width >= 64rem) {
  .tourism__content {
    grid-column: 8 / 13;
  }
}

.tourism__text {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.6rem;
}

.tourism__text--highlighted {
  color: #4c1d12;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 2rem;
}

.tourism__links {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  display: -ms-flexbox;
  display: flex;
}

.benefits {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.benefits__content {
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.25rem;
  display: -ms-flexbox;
  display: flex;
}

.benefits__cards {
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  gap: 1.25rem;
  display: grid;
}

@media (width >= 36rem) {
  .benefits__cards {
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  }
}

.benefits__card {
  background-color: #fff;
  border-radius: 4px;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
  padding: 1.25rem;
  display: -ms-flexbox;
  display: flex;
}

.benefits__card-content {
  grid-template-columns: subgrid;
  gap: 1rem;
  display: grid;
}

@media (width >= 36rem) {
  .benefits__card-content {
    display: -ms-flexbox;
    display: flex;
  }
}

.benefits__image {
  border-radius: 4px;
  width: 8rem;
  height: 8rem;
  display: block;
}

.benefits__subtitle {
  color: #978879;
}

.benefits__link {
  align-self: start;
}

.contacts {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.contacts__content {
  --columns: 2;
  grid-template-columns: repeat(var(--columns), 1fr);
  align-items: center;
  gap: 3rem 1.25rem;
  display: grid;
}

@media (width >= 36rem) {
  .contacts__content {
    --columns: 6;
    column-gap: 1.5rem;
  }
}

@media (width >= 64rem) {
  .contacts__content {
    --columns: 12;
  }
}

.contacts__form {
  grid-column: 1 / -1;
}

@media (width >= 36rem) {
  .contacts__form {
    grid-column: 1 / 4;
  }
}

@media (width >= 64rem) {
  .contacts__form {
    grid-column: 1 / 6;
  }
}

.contacts__image {
  border-radius: 4px;
  grid-column: 1 / -1;
  width: 100%;
  display: block;
}

@media (width >= 36rem) {
  .contacts__image {
    object-fit: cover;
    grid-column: 4 / 7;
    height: 100%;
  }
}

@media (width >= 64rem) {
  .contacts__image {
    grid-column: 7 / 13;
  }
}

.contacts__infoset {
  text-align: left;
  color: #333;
  grid-column: 1 / -1;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

@media (width >= 36rem) {
  .contacts__infoset {
    grid-column: 4 / 7;
  }
}

@media (width >= 64rem) {
  .contacts__infoset {
    grid-column: 8 / 11;
  }
}

.contact-info {
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.contact-info__link {
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: .35rem;
  transition: background-color .3s;
  display: -ms-flexbox;
  display: flex;
}

.contact-info__link:hover {
  background-color: #bfb0a1;
}

.contact-info__link:hover img {
  fill: #fff;
}

.form {
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

.form__subtitle {
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

@media (width >= 36rem) {
  .form__subtitle {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}

@media (width >= 64rem) {
  .form__subtitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.form__response {
  height: 1.25rem;
  margin-top: 1rem;
}

.fieldset {
  border: none;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.fieldset__field {
  box-sizing: border-box;
  color: #333;
  border: 1px solid #0000;
  border-radius: 4px;
  outline: none;
  width: 100%;
  padding: 1rem;
  transition: border-color .3s;
  display: -ms-flexbox;
  display: flex;
}

.fieldset__field::-moz-placeholder {
  opacity: .5;
}

.fieldset__field::placeholder {
  opacity: .5;
}

.fieldset__field:hover {
  border-color: #4c1d12;
}

.fieldset__field-wrapper {
  position: relative;
}

.fieldset__field--error, .fieldset__field--error:hover {
  border-color: #f44336;
}

.fieldset__button {
  background-color: #4c1d12;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.fieldset__button:hover {
  background-color: #33130a;
}

.error-tooltip {
  color: #fff;
  white-space: nowrap;
  z-index: 100;
  background: #f44336;
  border-radius: .25rem;
  margin-top: 5px;
  padding: 4px 8px;
  font-size: .75rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.socials {
  align-items: center;
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.socials__link {
  background-color: #fff;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: .35rem;
  transition: background-color .3s;
  display: -ms-flexbox;
  display: flex;
}

.socials__link:hover {
  background-color: #bfb0a1;
}

.socials__link:hover img {
  fill: #fff;
}

.socials__icon {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.footer {
  background-color: #4c1d12;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.footer__content {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.footer__wrapper {
  gap: 1rem;
  margin-bottom: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.footer__text {
  color: #fff;
  text-align: center;
  max-width: 30rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.rights {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.rights__link {
  color: #fff;
  cursor: pointer;
  font-size: .75rem;
  font-weight: 300;
  text-decoration: none;
}

.rights__text {
  color: #fff;
  font-size: .75rem;
  font-weight: 300;
}

.language-dropdown {
  width: max-content;
  min-width: 82px;
  transition: background .3s;
  display: inline-block;
  position: relative;
}

.language-dropdown:hover {
  background-color: #d3c4b5;
  border-radius: 4px;
}

.language-dropdown .language-toggle {
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  outline: none;
  align-items: center;
  padding: 8px;
  font-size: 1rem;
  font-weight: 600;
  display: -ms-flexbox;
  display: flex;
}

.language-dropdown .language-toggle svg {
  margin-left: 8px;
  transition: transform .2s ease-in-out;
}

.language-dropdown .language-options {
  background: #fff;
  border-radius: 4px;
  width: max-content;
  min-width: 82px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 4px 8px #0000001a;
}

.language-dropdown .language-options li {
  cursor: pointer;
  padding: 12px 16px;
  transition: background .3s;
}

.language-dropdown .language-options li:hover {
  background: #d3c4b5;
}

.language-dropdown.open .language-options {
  display: block;
}

.language-dropdown.open .language-toggle svg {
  transform: rotate(180deg);
}
/*# sourceMappingURL=index.522ea61e.css.map */
