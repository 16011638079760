.about {
  padding-block: 2rem;

  &__content {
    display: flex;
    gap: 20px;
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
      gap: 60px;
      margin-bottom: 2rem;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & p {
      font-size: 1.25rem;
      line-height: 1.6rem;
    }

    @include onDesktop {
      width: 50%;
    }
  }

  &__photo {
    display: block;
    object-fit: cover;
    border-radius: $border-radius;
    width: 100%;
    height: 18.75rem;

    @include onTablet {
      height: 25rem;
    }

    @include onDesktop {
      width: 50%;
      height: 31.25rem;
      margin-bottom: 0;
    }
  }

  &__links {
    display: flex;
    gap: 20px;
    margin-top: auto;
    flex-wrap: wrap;
  }
}
