.form {
  display: flex;
  flex-direction: column;

  &__subtitle {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 2rem;

    @include onTablet {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }

    @include onDesktop {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  &__response {
    margin-top: 1rem;
    height: 1.25rem;
  }
}

.fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  row-gap: 1rem;

  &__field {
    box-sizing: border-box;
    display: flex;
    padding: 1rem;
    border-radius: $border-radius;
    outline: none;
    border: 1px solid transparent;
    color: $main-text-color;
    transition: border-color $effect-duration;
    width: 100%;

    &::placeholder {
      opacity: 0.5;
    }

    &:hover {
      border-color: $burgundy-color;
    }

    &-wrapper {
      position: relative;
    }

    &--error {
      border-color: $red-color;

      &:hover {
        border-color: $red-color;
      }
    }
  }

  &__button {
    background-color: rgb(76, 29, 18);
    padding-block: 1rem;

    &:hover {
      background-color: $burgundy-dark-color;
    }
  }
}

.error-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: $red-color;
  color: $secondary-text-color;
  padding: 4px 8px;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  margin-top: 5px;
  white-space: nowrap;
  z-index: 100;
}

