.language-dropdown {
  position: relative;
  display: inline-block;
  min-width: 82px;
  width: max-content;
  transition: background $effect-duration;

  &:hover {
    background-color: $beige-light-color;
    border-radius: $border-radius;
  }

  .language-toggle {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    padding: 8px;
    color: #333;

    svg {
      margin-left: 8px;
      transition: transform 0.2s ease-in-out;
    }
  }

  .language-options {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    min-width: 82px;
    width: max-content;
    display: none;

    li {
      padding: 12px 16px;
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: $beige-light-color;
      }
    }
  }

  &.open .language-options {
    display: block;
  }

  &.open .language-toggle svg {
    transform: rotate(180deg);
  }
}
