.scroll-animated {
  opacity: 0;
  transition:
  opacity 2s,
  transform 2s;

  &--fade-in {
    transform: translateY(200px);
  }

  &--first-fade-in {
    transform: translateY(100px);
  }

  &--slide-in-left {
    transform: translateX(-100px);
  }

  &--slide-in-right {
    transform: translateX(200px);
  }

  &--zoom-in {
    transform: scale(0.5);
  }
}

.visible {
  opacity: 1;
  transform: none;
}
