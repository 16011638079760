.slider {
  display: grid;
  align-items: center;
  gap: 10px;
  justify-items: center;

  &__item {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;

    &:first-of-type,
    &:last-of-type {
      height: 250px;
      display: none;
    }
    &:nth-of-type(2),
    &:nth-of-type(4) {
      height: 341px;
      display: none;
    }
    &:nth-of-type(3) {
      height: 471px;
      object-position: top;
    }
  }
}

@media (min-width: 768px) {
  .slider {
    grid-template-columns: repeat(7, 1fr);
  }

  .slider__item:nth-of-type(2),
  .slider__item:nth-of-type(4) {
    display: block;
  }

  .slider__item:nth-of-type(3) {
    object-fit: cover;
    width: 100%;
    height: 421px;
    grid-column: 3 / 6;
  }
  .slider__item:nth-of-type(2) {
    grid-column: 1 / 3;
  }

  .slider__item:nth-of-type(4) {
    grid-column: 6 / 8;
  }
}

@media (min-width: 1200px) {
  .slider {
    grid-template-columns: repeat(6, 1fr);
  }

  .slider__item:first-of-type,
  .slider__item:last-of-type {
    display: block;
  }

  .slider__item:first-of-type {
    grid-column: 1 / 2;
  }

  .slider__item:last-of-type {
    grid-column: 6 / 7;
  }

  .slider__item:nth-of-type(2) {
    grid-column: 2 / 3;
  }

  .slider__item:nth-of-type(4) {
    grid-column: 5 / 6;
  }

  .slider__item:nth-of-type(3) {
    grid-column: 3 / 5;
  }
}
