.icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(../images/icons/icon-burger-menu.svg);
  background-size: cover;

  @include hover(transform, scale(1.2));

  @include onTablet {
    width: 2rem;
    height: 2rem;
  }

  &--menu {
    background-image: url(../images/icons/icon-burger-menu.svg);
    place-self: end;
    margin-top: 1.25rem;


    &:hover {
      background-image: url(../images/icons/icon-burger-menu-hover.svg);
    }

    @include onDesktop {
      display: none;
    }
  }
  &--close {
    background-image: url(../images/icons/icon-close.svg);

    @include onDesktop {
      display: none;
    }
  }
}
