.tourism {
  padding-block: 2rem;

  &__image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: $border-radius;
    margin-block-end: 1rem;
    object-fit: cover;

    flex: 0 0 80%;
    scroll-snap-align: start;
    margin-right: 10px;

    @include onTablet {
      flex: none;
      width: 100%;
      margin-bottom: 15px;
      break-inside: avoid;
    }
  }

  &__images-container {
    overflow: hidden;
    position: relative;
  }

  &__images {
    display: flex;
    gap: 15px;

    overflow-x: auto;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;

    @include onTablet {
      display: block;
      flex-wrap: wrap;
      columns: 10rem;

      grid-column: 1 / -1;
    }

    @include onDesktop {
      grid-column: 1 / 8;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__wrapper {
    @include onTablet {
      @include pageGrid;

      row-gap: 1rem;
    }
  }

  &__content {
    @include onTablet {
      grid-column: 1 / -1;
    }

    @include onDesktop {
      grid-column: 8 / 13;
    }
  }

  &__text {
    font-size: 1.25rem;
    line-height: 1.6rem;
    margin-block-end: 1rem;

    &--highlighted {
      font-size: 1.5rem;
      line-height: 2rem;
      color: $burgundy-color;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  &__links {
    flex-wrap: wrap;
    gap: 20px;
    margin-top: auto;
    display: flex;
  }
}
