.section-title {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: -1.5px;
  text-transform: uppercase;
  color: $burgundy-color;

  @include onTablet {
    font-size: 2rem;
  }

  &--align-center {
    text-align: center;
  }

  &--light {
    color: $secondary-text-color;
  }
}
