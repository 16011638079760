.page {
  font-family: Poppins, sans-serif;
  color: $main-text-color;
  font-size: 1rem;
  line-height: 140%;
  overflow-x: hidden;

  scroll-behavior: smooth;

  &:has(.page__menu:target) {
    overflow: hidden;
  }

  &__body {
    background-color: $main-bg-color;
    margin: 0;
    min-width: 20rem;
    overflow-x: hidden;
  }

  &__menu {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;

    transition: all $effect-duration;
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;

    @include onDesktop {
      display: none;
    }

    &:target {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
    }
  }
}
