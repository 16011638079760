.socials {
  display: flex;
  align-items: center;
  gap: 1rem;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.35rem;
    background-color: #fff;
    border-radius: $border-radius;
    transition: background-color $effect-duration;

    &:hover {
      background-color: $main-bg-color;
    }

    &:hover img {
      fill: white;
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
