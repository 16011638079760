.footer {
  background-color: $burgundy-color;
  padding-block: 4rem;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__wrapper {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__text {
    color: $secondary-text-color;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    max-width: 30rem;
  }
}

.rights {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &__link {
    text-decoration: none;
    color: $secondary-bg-color;
    cursor: pointer;
    font-weight: 300;
    font-size: 0.75rem;
  }

  &__text {
    color: $secondary-bg-color;
    font-weight: 300;
    font-size: 0.75rem;
  }
}
